.tab {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #FF9F07;
  border: 1px solid #0C9098;
  text-align: center;
  color: black;
  font-family: 'Permanent Marker', cursive;
}

.tab.active {
  background-color: #ffcf83;
}

.menu-content {
  border: 1px solid #0C9098;
  padding: 20px;
  background-color: white;
  margin-bottom: 30px;
  max-width: 850px;
}

.menu-item {
  margin-bottom: 20px;
}

.item-name {
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Caveat", cursive;
  font-size: 25px;
}

.item-price {
  color: #666;
}

.item-description {
  color: #000000;
}

.menu-tabs-container {
  display: flex;
  flex-direction: column;
  align-items: center; 
  max-width: 600px; 
  margin: 0 auto; 
  padding: 20px;
}

.tabs {
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
  width: 100%;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f0f0f0;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.tab.active {
  background-color: #ddd;
}

.menu-content {
  max-width: 500px;
  min-width: 500px;
}

.menu-item {
  margin-bottom: 15px;
}


@media (max-width: 767px) {
  .tabs {
    flex-direction: column; 
  }

  .tab {
    margin-right: 0;
  }
}


@media (min-width: 768px) {
  .desktop-tabs-wrapper {
    display: flex;
  }
  
  .mobile-tabs-wrapper {
    display: none;
  }
}

@media (max-width: 767px) {
  .desktop-tabs-wrapper {
    display: none;
  }

  .mobile-tabs-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }

 .mobile-tabs-wrapper .tab {
    max-width: 600px; 
    min-width: 200px;
    margin-bottom: 10px;
  }

  .menu-content {
    max-width: 300px;
    min-width: 300px;
  }
}



  






