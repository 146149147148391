.socials-container {
  top: 30px;
  right: 50px;
  margin-top: 10px;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.order-online {
  padding: 10px;
  background-color: #0c9098;
  color: white;
  font-weight: bold;
  text-decoration: none;
  border-radius: 8px;
  border-color: white;
}

.order-online a {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 767px) {
  .menu {
    margin-top: 200px;
  }
}
