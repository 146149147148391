footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

footer div {
  flex: 1;
  margin: 10px;
  min-width: 200px;
}

footer h4 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #ff9f07;
}

footer p {
  margin: 5px 0;
  font-size: 14px;
}

footer a {
  color: #ff9f07;
  text-decoration: none;
  display: block;
  margin: 5px 0;
}

footer a:hover {
  text-decoration: underline;
}
