@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
 
  .nav-bar {
    position: relative;
    z-index: 1000; 
    width: 100%;

  }
  
  .contact-page {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
  }
  
  .contact-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1500px;
  }
  
  .contact-form {
    width: 100%; 
    max-width: 600px; 
    margin-bottom: 20px;
  }
  
  .map-container {
    width: 100%;
    max-width: 600px; 
    
  }
  
  .address {
    margin-bottom: 20px;
    font-family: "Oswald", sans-serif;
  }

  .hours {
    list-style: none;
    
  }
  
  iframe {
    width: 100%;
    height: 250px;
    border-radius: 20px;
  }
  
  @media (min-width: 768px) {
    .contact-page {
      padding: 40px; 
    }
  
    .contact-page-container {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
  
    .contact-form {
      flex: 1;
      margin-right: 20px; 
      margin-bottom: 0; 
    }
  
    .map-container {
      flex: 1;
    }
  }
  
  

  
  