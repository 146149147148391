.socials-container {
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  margin-right: 30px;
  max-width: 600px;
}

.socials-container img {
  width: 60px; 
  height: auto; 
  margin-left: 10px;
}

.socials-container p {
  margin-right: 5px;
  font-family: "Cookie", cursive;
  font-size: 30px;
  color: #000000;
  text-shadow: -1px -1px 0 black;
  width: 75%;
}

@media (max-width: 767px) {
  .socials-container p {
    display: none;
  }

  .socials-container img {
    margin-right: -2px;
  }

  .socials-container {
    margin-right: 215px;
    
  }
}

