body {
  background-color: #fffad1;
}

.centered-content {
  text-align: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 650px;
  margin-top: 60px;
}

.centered-content p {
  text-align: left;
}

.centered-image {
  max-width: 100%;
  height: auto;
  max-height: 80vh;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .centered-content {
    text-align: center;
    justify-content: center;
    margin-top: 200px;
    padding: 30px;
  }
}
