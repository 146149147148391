@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  background-color: #fffad1;
  margin: 0;
  overflow-x: hidden;
}

.header-image {
  display: flex;
  justify-content: center;
}

.header-image img {
  max-width: 100%;
  height: auto;
  margin-top: 30px;
}

.top-corner-images {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.top-left-image,
.top-right-image {
  position: absolute;
  top: 0;
  width: 40%;
  height: auto;
}

.top-left-image {
  left: 0;
}

.top-right-image {
  right: 0;
}

.about-container {
  max-width: 3000px;
  height: auto;
}

.about-container h1 {
  color: #0c9098;
  font-family: "Titan One", sans-serif;
  margin-bottom: 20px;
}

.about-text {
  position: relative;
  background-color: rgba(12, 145, 152, 0.507);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.305);
  width: 80%;
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.image-container {
  position: relative;
  width: 72vw;
  height: 20vh;
}

.animated-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: auto;
  transition: left 0.2s ease-out;
}

.page-content > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.dynamic-cards {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.card {
  width: 350px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 10px;
  text-align: center;
}

.card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.artist-info {
  margin-top: 10px;
}

.artist-info h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.artist-info p {
  font-size: 14px;
  color: #888;
}

.card .card-image {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.card {
  width: 320px;
  height: auto;
  margin: 10px;
  overflow: hidden;
}

.entertainment-sched h1 {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  font-family: "Cookie", cursive;
  font-size: 55px;
}

@media (max-width: 768px) {
  .header-image {
    display: none;
  }

  .nav-bar-home {
    margin-top: 40px;
  }

  .top-left-image,
  .top-right-image {
    width: 40%;
  }

  .animated-image {
    width: 250px;
  }

  .image-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 20vh;
  }

  .page-content > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 300px;
  }

  .animated-image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 300px;
    transition: left 0.2s ease-out;
  }

  .about-text {
    width: 80%;
  }

  .dynamic-cards {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 300px;
    margin: 10px 0;
  }

  .card .card-image {
    width: 300px;
    height: 300px;
    object-fit: cover;
  }

  .card {
    width: 320px;
    height: auto;
    margin: 10px;
    overflow: hidden;
  }

  .contact-container-home {
    margin-top: -450px;
  }

  .entertainment-sched h1 {
    display: flex;
    margin-top: 40px;
    font-family: "Cookie", cursive;
    font-size: 50px;
    text-align: center;
  }
}
